const promotionCards = [
  {
    name: 'Vuoi vendere?',
    title: 'Diamo valore alla tua casa',
    imgSrc: 'ucrs-tip-vuoi-vendere-new.jpg',
    imgAlt: 'UniCredit RE Services, Valore Casa per chi vende casa',
    link: {
      href: '/vendo',
      text: 'Compila il form o chiamaci',
    },
  },
  {
    name: 'Valuta casa online: facile, veloce, affidabile',
    title: 'Gratis per te il nuovo tool di valutazione',
    imgSrc: 'ucrs-tip-valuta-casa.png',
    imgAlt: 'UniCredit RE Services, Report valore Casa',
    link: {
      href: '/valuta-casa',
      text: 'Scopri di più',
    },
  },
  // {
  //   name: 'Novità: servizi e documenti per la casa',
  //   title: 'Un Partner per ogni bisogno',
  //   imgSrc: 'ucrs-tip-buddy.png',
  //   imgAlt: 'UniCredit RE Services, Servizi e Documenti per la casa',
  //   link: {
  //     href: '/servizi-documenti-casa',
  //     text: 'Scopri di più',
  //   },
  // },
  {
    name: 'Tutte le Novità dal mondo Casa',
    title: 'Una guida chiara e completa',
    imgSrc: 'ucrs-tip-novita-mondo-casa.png',
    imgAlt: 'UniCredit RE Services, magazine',
    link: {
      href: '/news/come-fare/come-vendere-casa-rapidamente/',
      text: 'Come vendere casa velocemente',
    },
  },
]

const infoSearchFirstRow = [
  {
    icon: 'home-banking',
    title: 'Vendi casa con noi ',
    description:
      'Una valutazione affidabile e<br aria-hidden="true"> personalizzata',
  },
  {
    icon: 'laptop',
    title: 'Area riservata venditori',
    description:
      'Come procede la vendita del tuo<br aria-hidden="true"> immobile',
  },
  {
    icon: 'locator',
    title: 'Cerchi anche casa ?',
    description: "Avrai l'APE gratis",
  },
]

const infoSearchSecondRow = [
  {
    icon: 'search',
    title: 'Hai tutti i documenti in<br aria-hidden="true"> regola?',
    description: 'Acquistali sul nostro sito con un click',
    image: '/assets/img/ucrs-servizi-documenti-per-la-casa.webp',
  },
  {
    icon: 'add',
    title: 'Consulenza',
    description:
      'Sempre al tuo fianco un consulente non<br aria-hidden="true"> un semplice agente',
    image: 'assets/img/visual/ucrs-visual-scheda-patrimonio-immobiliare.png',
  },
  {
    icon: 'investement',
    title: 'Perché sceglierci',
    description: "Siamo l'agenzia immobiliare di UniCredit",
    image: 'assets/img/ucrs-perche-sceglierci.webp',
    link: {
      text: 'perché sceglierci',
      href: '/vendo',
    },
  },
]

export { promotionCards, infoSearchFirstRow, infoSearchSecondRow }
